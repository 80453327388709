

type OverViewCard2Props = {
    className: string
    value: any;
    description: string
    percent?: boolean
    total_active_coachees?: string | number
    self_serve_users?: string | number
    total_active_user?: string | number
}

const OverViewCard2 = (props: OverViewCard2Props) => {

    return (
        <div className={props.className}>
            <div className="px-4 py-5 sm:p-6">
                <div className="mt-2 font-bold max-w-xl text-3xl text-primary">
                    <p>{props.value}{props.percent && "%"}</p>
                </div>
                <div className="mt-5 py-3 rounded-b-lg text-blue">
                    {props.description}
                </div>
                <div className="mt-5 py-3 rounded-b-lg ">
                    {(props.total_active_user || props.total_active_user === 0) && <span className="italic">
                        Total Registered Users: {props.total_active_user}</span>}
                    {(props.self_serve_users ||
                        props.self_serve_users === 0) && <span className="italic">
                            Total Users Engaged Self-Awareness Tool: {props.self_serve_users}</span>}
                    {(props.total_active_coachees ||
                        props.total_active_coachees === 0) && <span className="italic">
                            Total Active Coachees: {props.total_active_coachees}</span>}
                </div>
            </div>
        </div>
    )
}

export default OverViewCard2

